/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.dashboardcomponentheader {
  clear: both;
  height: 40px;
}
.dashboardcomponentheader .dashboardcomponentheadertitle {
  float: left;
  padding: 10px;
}
.dashboardcomponentheader .dashboardcomponentheaderbuttons {
  float: right;
}
.dashboardcomponentheader .dashboardcomponentheaderbuttons .dashboardcomponentheaderbutton {
  padding: 5px;
  cursor: pointer;
}
.dashboardcomponentheader .dashboardcomponentheaderbuttons .dashboardcomponentheaderbutton:hover {
  color: #008cff;
}
.dashboardcomponentheader .dashboardcomponentheaderbuttons .dashboardcomponentheaderbutton.active {
  color: #008cff;
}
.dashboardcomponentdivider {
  border-left: 1px solid #cacaca;
  margin: 0 10px;
}
.insightchart {
  margin: 10px 10px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  background-color: #e8e8e8;
}
.insightchart svg.nvd3-svg {
  width: auto;
  height: auto;
}
.myGrid {
  width: 100%;
  height: 300px;
}
